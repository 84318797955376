.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.site {
  min-height: 100vh;
}

.site2 {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
}

.site-content {
  flex: 1 0 auto;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}